.banner-bg{
    position: relative;
    .image{
        img{
            border-radius: 10px;
        }
    }

    .content{
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .title{
            font-size: 25px;
            font-weight: 900;
            line-height: 1.32;
            span{
                font-weight: 300;
            }
        }
        a{
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1.7px;
            text-decoration: underline;
        }
    }
}