/*-----------------------------------------------------------------------------------
    Template Name: Template Name
    Version: 1.0
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Header
    03. Slider
    04. Shipping
    05. Product
    06. Banner
    07. Blog
    08. Testimonial
    09. Brand
    10. Breadcrumb
    11. About
    12. Shop
    13. Sidebar
    14. Contact
    15. My Account
    16. Login & Register
    17. Cart
    18. Wishlist
    19. Compare
    20. Checkout
    21. FAQ
    22. Privacy Policy
    23. 404
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/* Font Family

font-family: 'Cormorant Garamond', serif;
font-family: 'Poppins', sans-serif;

*/
/*-- Common Style --*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 380px) {
  html,
  body {
    height: auto;
  }
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #171717;
  background: #F7F7F7;
  line-height: 1.52;
}

.body-bg-alice-blue {
  background: #F3F8FF;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 25px;
  font-weight: 900;
  line-height: 1.4;
}

h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
}

h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.66;
}

h4 {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.56;
}

h5 {
  font-size: 14px;
  font-weight: 900;
  line-height: 1.56;
}

h6 {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.56;
}

a {
  color: #171717;
  text-decoration: none;
}

a:hover {
  color: #171717;
}

a,
button,
.btn,
img,
input {
  transition: all 0.3s ease;
}

a,
a:hover,
a:focus,
a:active,
button,
button:focus,
.btn,
.btn:focus,
input,
input:focus,
select,
textarea,
.swiper-pagination-bullet,
.swiper-slide,
.append-button,
.swiper-button-next,
.swiper-button-prev {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-full {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
}

.review-star {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  color: #ff9f0a;
  font-size: 14px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col-12 {
  padding-left: 15px;
  padding-right: 15px;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

.main-wrapper {
  position: relative;
  height: 100%;
}

.progress-btn {
  position: relative;
  display: inline-block;
}

.progress-btn::after {
  position: absolute;
  content: "";
  border-radius: 50%;
  top: 50%;
  left: 50%;
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  transform: translate(-50%, -50%) rotate(45deg);
  border: 2px solid rgba(255, 55, 95, 0.2);
}

.progress-btn--0::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  top: 50%;
  left: 50%;
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  transform: translate(-50%, -50%) rotate(45deg);
  border-top: 2px solid #ff375f;
  border-right: 2px solid rgba(255, 55, 95, 0);
  border-bottom: 2px solid rgba(255, 55, 95, 0);
  border-left: 2px solid rgba(255, 55, 95, 0);
}

.progress-btn--25::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  top: 50%;
  left: 50%;
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  transform: translate(-50%, -50%) rotate(45deg);
  border-top: 2px solid #ff375f;
  border-right: 2px solid rgba(255, 55, 95, 0);
  border-bottom: 2px solid rgba(255, 55, 95, 0);
  border-left: 2px solid rgba(255, 55, 95, 0);
}

.progress-btn--50::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  top: 50%;
  left: 50%;
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  transform: translate(-50%, -50%) rotate(45deg);
  border-top: 2px solid #ff375f;
  border-right: 2px solid #ff375f;
  border-bottom: 2px solid rgba(255, 55, 95, 0);
  border-left: 2px solid rgba(255, 55, 95, 0);
}

.progress-btn--75::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  top: 50%;
  left: 50%;
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  transform: translate(-50%, -50%) rotate(45deg);
  border-top: 2px solid #ff375f;
  border-right: 2px solid #ff375f;
  border-bottom: 2px solid #ff375f;
  border-left: 2px solid rgba(255, 55, 95, 0);
}

.progress-btn--100::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  top: 50%;
  left: 50%;
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  transform: translate(-50%, -50%) rotate(45deg);
  border-top: 2px solid #ff375f;
  border-right: 2px solid #ff375f;
  border-bottom: 2px solid #ff375f;
  border-left: 2px solid #ff375f;
}

.progress-btn:hover {
  color: white;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.pos-relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.setting-name {
  font-size: 18px;
  margin-bottom: 5px;
}

.setting-email {
  margin-bottom: 5 !important;
}

.default-form-wrapper.profile-wrapper {
  margin-top: 15px;
}

.image {
  position: relative;
}

.upload-file {
  opacity: 0;
  position: absolute;
}

.upload-image-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #ff9f0a;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: 0;
  cursor: pointer;
}

.upload-image-label i {
  font-size: 16px;
  line-height: 1;
}

.product-gallery-image {
  padding-top: 30px;
}

.btn {
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn--default {
  padding: 5px 10px;
  display: inline-block;
}

.btn--default:hover {
  color: white;
}

.btn--block {
  display: block;
}

.btn--round {
  border-radius: 50%;
}

.btn--radius {
  border-radius: 8px;
}

.btn--color-white {
  color: #fff;
}

.btn--color-radical-red {
  color: #FF375F;
}

.btn--color-pink-swan {
  color: #B4B4B4;
}

.btn--color-maya-blue {
  color: #64D2FF;
}

.btn--color-dodger-blue {
  color: #007AFF;
}

.btn--color-red-orange {
  color: #FF453A;
}

.btn--radical-red {
  background: #FF375F;
}

.btn--bg-white {
  background: #fff;
}

.btn--bg-pink-swan {
  background: #B4B4B4;
}

.btn--bg-maya-blue {
  background: #64D2FF;
}

.btn--bg-dodger-blue {
  background: #007AFF;
}

.btn--bg-red-orange {
  background: #FF453A;
}

.btn--box-shadow {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

.btn--size-33-33 {
  width: 33px;
  height: 33px;
}

.btn--size-40-40 {
  width: 40px;
  height: 40px;
}

.btn--size-58-58 {
  width: 58px;
  height: 58px;
}

.btn--size-xlarge {
  padding: 18px 0;
}

.btn--font-size-22 {
  font-size: 22px;
}

.btn--font-size-30 {
  font-size: 30px;
}

.btn--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn--social-large {
  padding-left: 20px;
  padding-right: 20px;
}

.btn--rotate-90 {
  transform: rotate(90deg);
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 0.6s linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.load-more {
  text-transform: capitalize;
  display: block;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

.load-more-btn {
  text-transform: capitalize;
  display: block;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background: #007aff;
  border-radius: 4px;
  padding: 10px 15px;
}

.apply-btn {
  text-transform: capitalize;
  line-height: 1;
  font-size: 14px;
  cursor: pointer;
  color: #007aff;
  background: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  border: 1px solid #007aff;
}

.cancel-btn {
  margin-left: 10px;
  text-transform: capitalize;
  line-height: 1;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background: #007aff;
  border-radius: 4px;
  padding: 10px 15px;
  border: 1px solid #007aff;
}

.btn-payment {
  text-transform: capitalize;
  line-height: 1;
  font-size: 14px;
  color: #fff;
  background: #007aff;
  border-radius: 4px;
  padding: 10px 15px;
  border: 1px solid #007aff;
}

.btn-payment:hover {
  color: #171717;
}

.skip-btn {
  margin-top: 30px;
  display: inline-block;
  text-transform: capitalize;
  font-weight: 500;
}

.section-gap-top-25 {
  margin-top: 25px;
}

.section-gap-top-30 {
  margin-top: 30px;
}

.section-gap-top-50 {
  margin-top: 50px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-115 {
  margin-top: 115px;
}

.title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 25px;
}

.section-content .title {
  font-size: 25px;
  font-weight: 900;
  line-height: 1.25;
}

.section-content .title span {
  font-weight: 400;
}

.section-content p {
  margin-top: 14px;
}

.search-n-filter-area {
  display: flex;
  gap: 18px;
  align-items: center;
  position: relative;
}

.search-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.search-box input {
  border-radius: 8px;
  height: 40px;
  width: 100%;
  border: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  padding: 10px 50px 10px 20px;
}

.search-box .btn {
  width: 50px;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  padding: 5px;
  color: #aaaaaa;
}

.search-box .filter_btn {
  color: #fff;
  border: 0;
  cursor: pointer;
}

.searchable {
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  flex-grow: 1;
  flex: 1;
}

.home-one .searchable {
  margin-left: 0px;
}

.searchable ul {
  padding: 15px 0px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 33;
  width: 100%;
  text-transform: capitalize;
  display: none;
  list-style-type: none;
  background-color: #fff;
  margin: 15px 0px 0px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 4px;
}

.searchable ul li {
  text-transform: capitalize;
  padding: 5px 15px;
  margin-bottom: 10px;
  cursor: pointer;
}

.searchable ul li:last-child {
  margin-bottom: 0;
}

.searchable ul li.selected {
  text-transform: capitalize;
  background-color: #ff375f;
  color: #fff;
}

.submit__btn,
.close__btn {
  display: none;
}

.btn.close__btn {
  right: 40px;
}

.shop-filter-block__brand {
  display: flex;
  flex-wrap: wrap;
}

.shop-filter-block__brand li {
  margin-right: 12px;
}

.shop-filter-block__brand li:last-child {
  margin-right: 0px;
}

.shop-filter-block__brand li button {
  padding: 0;
  margin: 0;
  background: transparent;
  color: #171717;
  border: 0;
}

.shop-filter-block {
  margin-top: 25px;
}

.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  background-color: #ff375f;
}

.irs--round .irs-from::before,
.irs--round .irs-to::before,
.irs--round .irs-single::before {
  border-top-color: #ff3860;
}

.irs--round .irs-handle {
  border: 4px solid #ff3860;
  box-shadow: 0 1px 3px rgba(255, 56, 96, 0.3);
}

.irs--round .irs-bar {
  background-color: #ff3860;
}

#shop-filter-menu {
  margin-top: 25px;
  box-shadow: 0px 0px 6px rgba(0, 128, 255, 0.1);
  background: #fff;
  padding: 25px 15px 25px;
  border-radius: 10px;
}

.mt-0 {
  margin-top: 0px !important;
}

.shop-filter {
  display: none;
}

.hero-singel-slide {
  position: relative;
}

.hero-area--style-1 .hero-bg {
  height: 160px;
}

@media (min-width: 576px) {
  .hero-area--style-1 .hero-bg {
    height: 240px;
  }
}

.hero-area--style-1 .hero-bg img {
  height: 100%;
  border-radius: 5px;
}

.hero-area--style-1 .inner-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
}

.hero-area--style-1 .inner-wrapper .content {
  width: 60%;
  text-align: center;
  text-transform: uppercase;
}

.hero-area--style-1 .inner-wrapper .content .title-tag {
  text-transform: capitalize;
  font-weight: 400;
  color: #FF375F;
}

.hero-area--style-1 .inner-wrapper .content .title {
  color: #fff;
}

.hero-area--style-1 .inner-wrapper .content .sub-title {
  font-weight: 300;
  letter-spacing: 1.5px;
  font-size: 12px;
}

.hero-area--style-1 .inner-wrapper .content .sub-title span {
  font-size: 25px;
  font-weight: 900;
  color: #FF375F;
}

.hero-area--style-1 .inner-wrapper .product-img {
  width: 40%;
  position: relative;
}

.hero-area--style-1 .inner-wrapper .shape {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
}

.hero-area--style-1.hero-area {
  position: relative;
}

.hero-area--style-1.hero-area .swiper-pagination-bullet {
  background: #383636;
  transition: all 0.3s ease;
  width: 7px;
  height: 7px;
  margin: 0 7px;
}

.hero-area--style-1.hero-area .swiper-pagination-bullet-active {
  width: 15px;
  background: #FF375F;
  border-radius: 3px;
}

.hero-area--style-1.hero-area .swiper-pagination {
  bottom: -25px;
}

.hero-area--style-2 {
  overflow: hidden;
  border-radius: 10px;
}

.hero-area--style-2 .hero-bg {
  height: 130px;
}

@media (min-width: 576px) {
  .hero-area--style-2 .hero-bg {
    height: 240px;
  }
}

.hero-area--style-2 .hero-bg img {
  height: 100%;
  border-radius: 5px;
}

.hero-area--style-2 .inner-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.hero-area--style-2 .inner-wrapper .content {
  text-align: center;
  text-transform: capitalize;
  color: #fff;
}

.hero-area--style-2 .inner-wrapper .content .title {
  font-size: 25px;
  font-weight: 900;
}

.hero-area--style-2 .inner-wrapper .content .sub-title {
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 1.5px;
  margin-top: -10px;
}

.catagories-nav-1 .swiper {
  padding: 40px 15px;
  margin: -40px -15px;
}

.catagories-nav-1 .swiper-slide {
  width: auto;
}

.catagories-nav-1 .btn {
  padding: 10px 10px;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  background: #fff;
  font-weight: 500;
  box-shadow: 13px 15px 30px rgba(0, 17, 252, 0.08);
}

.catagories-nav-1 .btn .icon img {
  width: 33px;
  height: 33px;
}

.catagories-nav-1 .btn:hover {
  box-shadow: 13px 15px 30px rgba(0, 17, 252, 0.08);
  color: #0011fc;
}

.catagories-nav-1.catagories-nav-modyfied .swiper {
  padding: 10px;
  margin: -10px;
}

.catagories-nav-1.catagories-nav-modyfied .btn {
  width: 58px;
  height: 60px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(94, 105, 255, 0.16);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-weight: 500;
}

.catagories-nav-1.catagories-nav-modyfied .btn .icon img {
  width: 33px;
  height: 33px;
}

.catagories-nav-1.catagories-nav-modyfied .btn:hover {
  box-shadow: 13px 15px 30px rgba(0, 17, 252, 0.08);
  color: #0011fc;
}

.catagories-nav-2 .swiper-slide {
  width: auto;
}

.catagories-nav-2 .btn {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  background: #fff;
  border-radius: 20px;
  display: inline-block;
}

.catagories-nav-2 .btn:hover {
  color: #007AFF;
}

.catagories-nav-3 {
  display: flex;
  gap: 10px;
}

@media (min-width: 576px) {
  .catagories-nav-3 {
    gap: 25px;
  }
}

.catagories-nav-3 .btn {
  padding: 20px 15px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(94, 105, 255, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
}

.catagories-wrapper-content {
  display: grid;
  grid-gap: 15px 8px;
  grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 576px) {
  .catagories-wrapper-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

.catagories-shop-wrapper {
  margin-top: 50px;
}

.product-wrapper-content--4 {
  overflow: hidden;
}

.product-wrapper-content--4 .product-item--style-4:not(:last-child) {
  margin-bottom: 20px;
}

.product-item--style-1 {
  border-radius: 8px;
  padding: 20px 10px;
}

@media (min-width: 576px) {
  .product-item--style-1 {
    padding: 20px 20px;
  }
}

.product-item--style-1 .image {
  display: block;
  text-align: center;
}

.product-item--style-1 .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  font-size: 16px;
}

.product-item--style-1 .content--right .btn {
  background: rgba(255, 255, 255, 0.3);
}

.product-item--style-1 .content--right .btn:hover {
  color: #FF375F;
}

.product-item--style-1 .price {
  display: block;
  font-weight: 900;
}

.product-item--style-2 {
  border-radius: 8px;
}

.product-item--style-2 .image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: calc(100% - 20px);
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}

.product-item--style-2 .image .btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.3);
}

.product-item--style-2 .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -20px;
  background: #fff;
  padding: 40px 10px 15px 10px;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: 0 0 6px rgba(93, 104, 255, 0.16);
}

.product-item--style-2 .content .price {
  display: block;
  font-weight: 900;
}

.product-item--style-2 .content .review-star-text {
  font-weight: 500;
}

.product-item--style-2 .content .review-star-text i {
  color: #ff9f0a;
}

.product-item--style-3 {
  border-radius: 8px;
  background: #fff;
  padding: 20px 25px;
}

.product-item--style-3 .image {
  position: relative;
  margin: 0 auto;
  text-align: center;
  display: block;
}

.product-item--style-3 .image .btn {
  position: absolute;
  top: -8px;
  left: -8px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #e8e8e8;
  background: #fff;
}

.product-item--style-3 .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 30px;
  border-radius: 5px;
  font-size: 16px;
}

.product-item--style-3 .content .price {
  display: block;
  font-weight: 900;
}

.product-item--style-4 {
  border-radius: 8px;
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 25px;
  position: relative;
  transition: all 0.5s ease;
}

.product-item--style-4 .image {
  width: 90px;
  height: 90px;
  background: rgba(100, 210, 255, 0.3);
  border-radius: 10px;
}

.product-item--style-4 .image img {
  width: 100%;
  height: 100%;
}

.product-item--style-4 .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: calc(100% - 115px);
  font-size: 16px;
}

.product-item--style-4 .content .price {
  display: block;
  font-weight: 900;
}

.product-item--style-4 .content .btn {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
}

.product-item--style-4 .content .btn:hover {
  color: #FF375F;
}

.product-item--style-4 .cart-link {
  position: absolute;
  width: 65px;
  height: 100%;
  background: rgba(100, 210, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  right: calc(0% - 75px);
  color: #007AFF;
  font-size: 22px;
}

.product-item--style-4.active {
  transform: translateX(-75px);
}

.product-item--bg-lime-green {
  background: rgba(50, 215, 75, 0.18);
}

.product-item--bg-maya-blue {
  background: rgba(100, 210, 255, 0.18);
}

.product-item--bg-gold {
  background: rgba(255, 214, 10, 0.18);
}

.product-item--bg-red-orange {
  background: rgba(255, 69, 58, 0.18);
}

.product-item--bg-neon-carrot {
  background: #f8e3d0;
}

.product-item--bg-blue-violet {
  background: #dccff2;
}

.product-item--bg-colonial-white {
  background: #f3efe4;
}

.product-item--bg-solid-pink {
  background: #dfd5d6;
}

.product-item--bg-roti {
  background: #ece5d3;
}

.product-item--bg-prelude {
  background: #edeaee;
}

.short-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-weight: normal;
  font-size: 14px;
  color: #8e8e93;
  cursor: pointer;
}

.short-btn:hover {
  color: #FF375F;
}

.short-btn span {
  display: block;
  margin-left: 10px;
}

.short-section {
  display: none;
}

.short-wrapper {
  background: #fff;
  box-shadow: 0 -11px 16px rgba(93, 104, 255, 0.16);
  border-radius: 30px 30px 0 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
  padding: 30px 0px 60px 0px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
}

@media (min-width: 576px) {
  .short-wrapper {
    max-width: 540px;
  }
}

.short-wrapper .title {
  font-size: 16px;
  font-weight: 900;
  text-decoration: underline;
  text-align: center;
  display: block;
  margin-bottom: 10px;
}

.short-select-list {
  font-size: 16px;
  padding: 0 20px;
}

.short-select-list .list-item {
  padding: 20px 65px;
  border-bottom: 1px solid #ececec;
}

.short-select-list label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.short-select-list input[type="radio"] {
  appearance: none;
  cursor: pointer;
}

.short-select-list .sort-radio-btn {
  position: relative;
}

.short-select-list .sort-radio-btn::after {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border: 3px solid #dedede;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.short-select-list input[type="radio"]:checked + .sort-radio-btn::after {
  border-color: #007AFF;
}

.short-btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-top: 40px;
}

.short-btn-group .btn {
  font-size: 18px;
  padding: 18px 42.5px;
  border-radius: 30px;
}

.short-btn-group .btn-cancel {
  color: #000;
  background: rgba(100, 210, 255, 0.3);
}

.short-btn-group .btn-apply {
  color: #fff;
  background: #64d2ff;
}

.user-event-section {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.user-event-area {
  width: 100%;
  height: 80px;
  display: flex;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .user-event-area {
    max-width: 540px;
  }
}

.user-event-area .user-event {
  background-color: #fff;
  height: 100%;
  display: flex;
}

.user-event-area .user-event--left, .user-event-area .user-event--right {
  width: calc(50% - 38px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 45px;
  font-size: 22px;
}

.user-event-area .user-event--left {
  border-radius: 0 8px 0 0;
}

.user-event-area .user-event--right {
  border-radius: 8px 0 0 0;
}

.user-event-area .user-event--center {
  width: 76px;
  background: rgba(255, 255, 255, 0);
  transform: translateY(40px);
  position: relative;
}

.user-event-area .user-event--center::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  bottom: 38px;
  left: 0;
  z-index: -1;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-image: radial-gradient(top, circle closest-corner, rgba(23, 6, 255, 0) 0, rgba(16, 187, 101, 0) 98%, white 100%, white 100%);
}

.user-event-area .user-event--center .event-btn-link {
  position: absolute;
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 -11px 25px rgba(75, 99, 255, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #007AFF;
}

.user-event-area .user-event--center .event-btn-link:hover {
  color: #FF375F;
}

.event-btn-link {
  color: #b7b9c8;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.event-btn-link:hover {
  color: #FF375F;
}

.banner-bg {
  position: relative;
}

.banner-bg .image img {
  border-radius: 10px;
}

.banner-bg .content {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.banner-bg .content .title {
  font-size: 25px;
  font-weight: 900;
  line-height: 1.32;
}

.banner-bg .content .title span {
  font-weight: 300;
}

.banner-bg .content a {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.7px;
  text-decoration: underline;
}

.product-gallery-large {
  position: relative;
}

.product-gallery-large .gallery-nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  width: 100%;
  z-index: 1;
}

.product-gallery-large .gallery-nav-btn .text-btn {
  cursor: pointer;
}

.product-gallery-large .product-tag {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  width: 100%;
  z-index: 1;
}

.product-gallery-single-item .image {
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 272px;
}

.product-gallery-single-item .image .img-fluid {
  max-width: 250px;
}

.product-gallery-single-item .image-shape {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  border-radius: 50%;
  transition: all 0.5s linear;
  opacity: 0;
  visibility: hidden;
}

.product-gallery-single-item .image-shape-1 {
  height: 175px;
  width: 175px;
  background: rgba(255, 255, 255, 0.3);
  z-index: -1;
  transition-delay: 0.5s;
}

.product-gallery-single-item .image-shape-2 {
  height: 271px;
  width: 271px;
  background: #52ffff;
  background: linear-gradient(148deg, #52ffff 0%, #4bf2f8 17%, #39d2e6 50%, #1c9fca 94%, #1b9cc9 100%);
  z-index: -2;
  transition-delay: 0.3s;
}

.product-thumb-image {
  margin: 0 auto;
  max-width: 190px;
}

.product-thumb-image .product-thumb-single-item .image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
  margin: 0 auto;
}

.product-thumb-image .product-thumb-single-item .image img {
  height: 100%;
  width: 100%;
}

.product-thumb-image .swiper-slide-thumb-active .product-thumb-single-item .image {
  background: #ccc;
}

.product-gallery-image {
  position: relative;
}

.product-gallery-image .swiper-slide-active .image-shape {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.product-gallery-image .swiper-pagination-bullet {
  transition: all 0.3s ease;
  width: 7px;
  height: 7px;
}

.product-gallery-image .swiper-pagination-bullet-active {
  width: 15px;
  background: #25AFD2;
  border-radius: 3px;
}

.product-gallery-image .swiper-pagination {
  bottom: -37px;
}

.product-gallery-image .product-tag {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.product-gallery-image .product-tag .tag-discount {
  font-size: 10px;
  color: #fff;
  background: rgba(50, 215, 75, 0.7);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.product-gallery-details {
  margin-top: 50px;
  background: #fff;
  border-radius: 50px 50px 0 0;
  padding: 30px;
}

@media screen and (max-width: 480px) {
  .product-gallery-details {
    padding: 30px 20px;
  }
}

.product-gallery-details .rating {
  display: inline-block;
  margin-bottom: 4px;
}

.product-gallery-details .title {
  color: #007AFF;
  margin-bottom: 7px;
}

.product-variable-lists {
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-variable-lists .list-item {
  display: flex;
  align-items: center;
}

.product-variable-lists .list-item:not(:last-child) {
  margin-bottom: 25px;
}

.product-variable-lists .left {
  width: 13%;
  font-size: 18px;
}

@media screen and (max-width: 480px) {
  .product-variable-lists .left {
    width: 20%;
  }
}

.product-variable-lists .right {
  width: auto;
}

.product-variable-lists .inner-child-item {
  display: flex;
  gap: 15px;
}

@media screen and (max-width: 480px) {
  .product-variable-lists .inner-child-item {
    gap: 10px;
  }
}

.product-variable-lists .inner-child-item label {
  position: relative;
  width: 36px;
  height: 36px;
  display: block;
}

.product-variable-lists .inner-child-item input[type="radio"] {
  appearance: none;
  transition: all 0.3s ease;
}

.product-variable-lists .inner-child-item .size-chart label {
  width: 36px;
  height: 36px;
}

.product-variable-lists .inner-child-item .size-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  transition: all 0.1s ease;
}

.product-variable-lists .inner-child-item input[type="radio"]:checked + .size-text {
  background: rgba(100, 210, 255, 0.6);
  border-color: rgba(100, 210, 255, 0.6);
}

.product-variable-lists .color-chart label {
  width: 25px;
  height: 25px;
}

.product-variable-lists .color-chart .color-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.1s ease;
}

.product-variable-lists .color-chart .color-box--blue {
  background: #25AFD2;
}

.product-variable-lists .color-chart .color-box--orange {
  background: #FF453A;
}

.product-variable-lists .color-chart .color-box--violet {
  background: #7230E0;
}

.product-variable-lists .color-chart .color-box--pink {
  background: #FF375F;
}

.product-variable-lists .color-chart input[type="radio"]:checked + .color-box {
  border: 5px solid rgba(255, 255, 255, 0.3);
}

.price-n-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  position: relative;
  margin-top: 25px;
}

.price-n-cart .price {
  font-size: 25px;
  font-weight: 600;
  color: #007AFF;
}

.price-n-cart .cart {
  position: absolute;
  background: #007AFF;
  border-radius: 50px 0 0 50px;
  width: 218px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -30px;
  color: #fff;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .price-n-cart .cart {
    width: 180px;
    right: -20px;
  }
}

.price-n-cart .cart .icon {
  font-size: 18px;
  display: inline-block;
  margin-right: 7px;
}

.cart-item-list .single-cart-item:not(:last-child) {
  margin-bottom: 15px;
}

.single-cart-item {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

@media (min-width: 576px) {
  .single-cart-item {
    padding: 10px 30px 10px 10px;
  }
}

.single-cart-item .image {
  width: 90px;
  height: 90px;
  background: rgba(100, 210, 255, 0.18);
  border-radius: 10px;
  margin-right: 15px;
}

@media (min-width: 576px) {
  .single-cart-item .image {
    margin-right: 25px;
  }
}

.single-cart-item .image img {
  border-radius: 10px;
  width: 90px;
  height: 90px;
}

.single-cart-item .content {
  position: relative;
  width: calc(100% - 75px);
}

.single-cart-item .content .title {
  font-size: 16px;
  margin-bottom: 4px;
}

.single-cart-item .content .brand,
.single-cart-item .content .price {
  display: block;
}

.single-cart-item .content .brand {
  font-size: 14px;
  color: #8e8e93;
  margin-bottom: 4px;
}

.single-cart-item .content .price {
  font-weight: 900;
}

.single-cart-item .content .delete-item {
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  color: #171717;
  position: absolute;
  top: 4px;
  right: 0;
  line-height: 1;
}

.single-cart-item .content .delete-item:hover {
  color: #ff375f;
}

.single-cart-item .content .delete-item svg {
  fill: currentColor;
  width: 15px;
  height: auto;
}

.single-cart-item .details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/*Quantity */
.num-in {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #ffffff;
  border-radius: 4px;
  height: 34px;
  display: flex;
  width: 98px;
}

.num-in span {
  width: 33.33%;
  display: block;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.num-in span:before,
.num-in span:after {
  content: "";
  position: absolute;
  background-color: #7230E0;
  height: 2px;
  width: 10px;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -5px;
}

.num-in span.plus:after {
  transform: rotate(90deg);
}

.num-in input {
  width: 33.33%;
  height: auto;
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #7230E0;
}

.cart-info-list {
  margin-top: 25px;
}

.cart-info-single-list:not(:last-child) {
  border-bottom: 1px solid #bbecff;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.cart-info-child {
  font-size: 14px;
  font-weight: 500;
}

.cart-info-child .item {
  display: flex;
  align-items: center;
}

.cart-info-child .item:not(:last-child) {
  margin-bottom: 13px;
}

.cart-info-child .text-left {
  position: relative;
  width: 26%;
  margin-right: 20px;
}

@media (min-width: 576px) {
  .cart-info-child .text-left {
    width: 20%;
    margin-right: 30px;
  }
}

.cart-info-child .text-left::after {
  position: absolute;
  content: ":";
  right: 0;
}

.cart-info-child .total-price {
  font-size: 20px;
  color: #000;
  margin-right: 20px;
  display: inline-block;
}

@media (min-width: 576px) {
  .cart-info-child .total-price {
    margin-right: 40px;
  }
}

.cart-info-child .btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  background: #007AFF;
  border-radius: 4px;
  padding: 10px 15px;
}

@media (min-width: 576px) {
  .cart-info-child .btn {
    padding: 8px 15px;
  }
}

.cart-info-child .btn .icon {
  padding-right: 5px;
}

.profile-card-wrapper {
  position: relative;
  padding: 35px 40px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 25px;
}

.profile-card-wrapper h2 {
  font-size: 18px;
}

.profile-card-wrapper .profile-shape {
  position: absolute;
}

.profile-card-wrapper .profile-shape-1 {
  top: 0;
  left: 0;
}

.profile-card-wrapper .profile-shape-2 {
  bottom: -5px;
  right: 0;
}

.profile-card-wrapper .image {
  border-radius: 50%;
}

.profile-card-wrapper .image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile-card-wrapper .content .title {
  font-weight: 500;
  margin-bottom: 2px;
}

.profile-card-wrapper .content .email,
.profile-card-wrapper .content .id-num {
  display: block;
}

.profile-card-wrapper .content .email {
  margin-bottom: 8px;
}

.profile-card-wrapper .content .id-num {
  font-size: 13px;
}

.profile-details-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-details-top .text {
  display: flex;
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 10px;
  color: #929292;
}

.profile-details-top .price {
  font-weight: 900;
  font-size: 16px;
  color: #1c1c1e;
}

.profile-details-bottom {
  margin-top: 30px;
}

.profile-user-list .profile-list-item {
  border-top: 1px solid rgba(112, 112, 112, 0.1);
  padding-top: 25px;
  padding-bottom: 25px;
}

.profile-single-list .list-item:not(:last-child) {
  margin-bottom: 28px;
}

.profile-single-list .profile-link {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 500;
}

.profile-single-list .icon {
  font-size: 18px;
  line-height: 1;
}

.login-section {
  margin-top: 30px;
  margin-bottom: 30px;
}

.login-wrapper .devide-text {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.login-wrapper .btn:not(:last-child) {
  margin-bottom: 30px;
}

.login-wrapper .btn .icon {
  width: 30px;
  border-right: 1px solid #fff;
  margin-right: 30px;
  display: inline-block;
}

.login-wrapper .btn:hover .icon {
  border-color: #171717;
}

.default-form-wrapper {
  width: 100%;
  margin-top: 30px;
}

.default-form-list .single-form-item:not(:last-child) {
  margin-bottom: 15px;
}

.single-form-item {
  position: relative;
}

.single-form-item input {
  border: 2px solid #e2e7ea;
  border-radius: 10px;
  padding: 18px 20px 18px 80px;
  width: -webkit-fill-available;
  font-size: 14px;
  width: 100%;
}

.single-form-item input::placeholder {
  color: #171717;
}

.single-form-item .icon {
  position: absolute;
  height: calc(100% - 20px);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  font-size: 16px;
  border-right: 2px solid #e2e7ea;
}

.forgot-link {
  margin-top: 25px;
  display: block;
  text-align: end;
  margin-bottom: 30px;
}

.create-account-text {
  margin-top: 30px;
  margin-bottom: 40px;
}

.sign-account-text {
  margin-top: 30px;
  margin-bottom: 40px;
}

.page-progress-wrapper {
  display: flex;
  justify-content: center;
}

.contact-btn {
  margin-top: 15px;
}

.register-space-top {
  margin-top: 15px;
}

.intro-section {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #F3F8FF;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.intro-wrapper {
  text-align: center;
}

.intro-wrapper .logo {
  margin-bottom: 30px;
}

.intro-wrapper .logo .image {
  display: block;
  margin-bottom: 10px;
}

.intro-wrapper .intro-bg {
  margin-bottom: 30px;
}

.intro-wrapper .intro-text {
  margin-bottom: 50px;
}

.btn--size-58-58 {
  margin: 0 auto;
}

.chat-top-area {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  color: #171717;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 20px rgba(51, 97, 248, 0.05);
}

.chat-top-area .image {
  margin-right: 15px;
  position: relative;
}

.chat-top-area .image img {
  border-radius: 50%;
  border: 2px solid #FF375F;
}

.chat-top-area .image .chat-activity {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 10px;
  animation: flashing 1.5s infinite linear;
}

.chat-top-area .image .chat--online {
  background: #35E86A;
}

.chat-top-area .content {
  flex: 1;
}

@keyframes flashing {
  0%,
  90% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
}

.chat-content-area {
  margin-top: 30px;
  padding-bottom: 90px;
}

.message-content {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

.message-content:not(:last-child) {
  margin-bottom: 20px;
}

.message-content .image img {
  border-radius: 50%;
  border: 2px solid #fff;
}

.message-content p {
  padding: 7px 15px;
  color: #fff;
  display: inline-block;
  border-radius: 0 15px 15px 15px;
}

.message-content p:not(:last-child) {
  margin-bottom: 5px;
}

.message-content .time {
  display: block;
  font-size: 12px;
}

.message-content .dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #007AFF;
  animation: writingdot 1s linear 0s infinite;
  display: inline-block;
}

.message-content .dot:nth-child(1) {
  animation-delay: 0s;
}

.message-content .dot:nth-child(2) {
  animation-delay: 0.25s;
}

.message-content .dot:nth-child(3) {
  animation-delay: 0.5s;
}

.agent-message-content {
  justify-content: flex-start;
}

.agent-message-content p {
  color: #171717;
  background: #fff;
  box-shadow: 0px 3px 20px rgba(51, 97, 248, 0.05);
  border-radius: 0 15px 15px 15px;
}

.user-message-content {
  justify-content: flex-end;
}

.user-message-content p {
  border-radius: 15px 0px 15px 15px;
  background: #007AFF;
}

@keyframes writingdot {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
}

.form-group .form-single-item label {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group .form-single-item input[type="file"] {
  display: none;
}

.form-group .form-attach,
.form-group .form-submit {
  width: 60px;
  color: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}

.form-group .form-text {
  flex-grow: 8;
  display: flex;
  flex: 1;
}

.form-group .form-text textarea {
  background-color: #f7f7f7;
  resize: none;
  padding: 8px 15px;
  overflow-y: auto;
  width: 100%;
  font-size: 14px;
  border: 0;
  border-radius: 30px;
}

.chat-bottom-area {
  background-color: #fff;
  border-radius: 0px 0px 30px 30px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 2;
  left: 0;
  right: 0;
  padding: 10px 0px;
}

.chat-bottom-area .form-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.chat-bottom-area svg {
  fill: #007AFF;
  width: 15px;
  height: auto;
}

.billing-information-card {
  margin-bottom: 30px;
  border-radius: 30px 30px 0px 0px;
  background: #fff;
  box-shadow: 0px -11px 16px rgba(61, 113, 255, 0.16);
}

.billing-information-title {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  padding: 25px 25px 0px;
}

.billing-information-lists {
  padding: 15px 25px 50px;
}

.billing-information-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.billing-information-list:last-child {
  margin-bottom: 0;
}

.billing-information-edit-btn {
  width: 100%;
  display: block;
  margin-top: 10px;
}

.shipping-method-card {
  margin-bottom: 30px;
  border-radius: 30px 30px 0px 0px;
  background: #fff;
  box-shadow: 0px -11px 16px rgba(61, 113, 255, 0.16);
}

.shipping-method-title {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  padding: 0px 20px 0px;
}

.shipping-method-lists {
  padding: 15px 25px 25px;
}

.shipping-method-list {
  display: block;
  position: relative;
}

.shipping-method-list input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.shipping-method-list label {
  display: block;
  position: relative;
  padding: 8px 0px 8px 30px;
  z-index: 9;
  cursor: pointer;
  font-size: 14px;
}

.shipping-method-list .check {
  display: block;
  position: absolute;
  border: 2px solid #FF375F;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  top: 50%;
  margin-top: -10px;
  left: 0px;
  z-index: 5;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.shipping-method-list .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 6px;
  width: 6px;
  top: 50%;
  left: 50%;
  margin: -3px;
  -webkit-transition: background 0.25s linear;
  transition: background 0.25s linear;
}

.shipping-method-list input[type="radio"]:checked ~ .check::before {
  background: #FF375F;
}

.shipping-method-list span i {
  margin-right: 5px;
}

.shipping-method-edit-btn {
  width: 100%;
  display: block;
}

.payment-card {
  border-top: 1px solid #ececec;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
}

.payment-card .total-price {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

.contact-image {
  margin-bottom: 50px;
}

.contact-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*----------------------------------------*/
/*  02. Header
/*----------------------------------------*/
.header-top-area {
  margin-top: 10px;
}

.header-top-area .event-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-top-area .event-list .list-child {
  display: flex;
  gap: 25px;
  justify-content: flex-end;
}

.header-top-area .event-list .list-child .list-item .notch-bg {
  position: relative;
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: 7px;
  left: 25px;
  z-index: 1;
}

.header-top-area .event-list .list-child .list-item .notch-bg--sunset-orange {
  background: #FF5050;
}

.header-top-area .event-list .list-child .list-item .notch-bg--emerald {
  background: #35E86A;
}

.header-top-area .event-list .btn {
  font-size: 16px;
}

.header-top-area .event-list .btn img {
  border-radius: 50%;
}

.header-top-area .main-menu {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-top-area .main-menu .menu-line {
  height: 2px;
  width: 18px;
  background: #FF375F;
  display: block;
}

.header-top-area .main-menu .menu-line:not(:first-child) {
  margin-top: 4px;
}

.header-top-area--style-1 .event-list > .list-item {
  width: 33.333%;
}

.header-top-area--style-2 .event-list {
  justify-content: flex-start;
  gap: 20px;
}

.header-top-area--style-2 .event-list .searchable {
  margin-left: 0;
  margin-right: 0;
}

.header-top-area--style-2 .event-list .list-item {
  width: auto;
}

.header-top-area--style-2 .event-list .list-item:nth-child(2) {
  width: 100%;
}

.header-top-area--style-2 .event-list .list-item ~ .search-box {
  width: 100%;
}

.header-top-area--style-2 .event-list .list-item .search-box {
  width: 100%;
}

.header-top-area--style-3 .event-list {
  justify-content: space-between;
  gap: 20px;
}

.header-top-area--style-3 .list-item {
  width: auto;
}

.header-top-area--style-3 .list-item:nth-child(2) {
  width: 100%;
}

/*----------------------------------------*/
/*  16. Footer
/*----------------------------------------*/
.footer-section {
  padding-top: 120px;
}

/*****************************
* Layout - Offcanvas
*****************************/
.offcanvas {
  z-index: 999999;
  position: fixed;
  top: 0;
  overflow: auto;
  height: 100%;
  padding: 20px;
  transition: all 0.3s ease;
  transform: translateX(100%);
  box-shadow: none;
  background: #f7f7f7;
  width: 100%;
  overflow-x: hidden;
}

.offcanvas-leftside {
  left: 0%;
  transform: translateX(-100%);
}

.offcanvas-rightside {
  right: 0%;
  transform: translateX(100%);
}

.offcanvas.offcanvas-open {
  transform: translateX(0);
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas-header {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.offcanvas-close {
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
}

.offcanvas-title {
  margin-bottom: 40px;
}

.offcanvas-modify {
  position: relative;
  align-items: center;
}

.offcanvas-modify span {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.filter-back-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

/*Mobile Menu Bottom Area*/
.offcanvas-menu {
  margin: 20px 0;
}

.offcanvas-menu li {
  position: relative;
  padding: 7px 0px;
}

.offcanvas-menu > ul > li {
  border-bottom: 1px solid transparent;
}

.offcanvas-menu > ul > li:last-child {
  border-bottom: none;
}

.offcanvas-menu li a {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}

.offcanvas-menu li .mobile-sub-menu {
  display: none;
}

.offcanvas-menu-expand::before,
.offcanvas-menu-expand::after {
  position: absolute;
  top: 17px;
  right: 0px;
  z-index: 10;
  height: 1px;
  width: 15px;
  opacity: 1;
  background-color: #FF375F;
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  content: "";
}

.offcanvas-menu-expand::after {
  transform: rotate(90deg);
}

.offcanvas-menu .active > .offcanvas-menu-expand:last-child,
.sub-menu > .active .offcanvas-menu-expand:last-child {
  border-bottom: 1px solid #2f3239;
}

.offcanvas-menu .active > .offcanvas-menu-expand::after,
.sub-menu > .active .offcanvas-menu-expand::after {
  transform: rotate(0deg);
}

/*Mobile Menu Contact Info*/
.mobile-contact-info .logo {
  margin: 0 auto;
  width: 100px;
}

.mobile-contact-info .logo img {
  width: 100%;
  height: auto;
}

.mobile-contact-info .address {
  margin-top: 30px;
}

.mobile-contact-info .address span {
  display: block;
}

.mobile-contact-info .social-link li {
  display: inline-block;
  margin-right: 20px;
}

.mobile-contact-info .social-link li:last-child {
  margin-right: 0;
}

.mobile-contact-info .social-link li a {
  display: block;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #2f3239;
  transition: all 0.3s ease;
  font-size: 16px;
}

.mobile-contact-info .social-link li a:hover {
  color: #2f3239;
}

.mobile-contact-info .user-link {
  margin-top: 40px;
}

.mobile-contact-info .user-link li {
  display: inline-block;
  margin-right: 15px;
}

.mobile-contact-info .user-link li:last-child {
  margin-right: 0;
}

.mobile-sub-menu a {
  padding-left: 10px;
  font-size: 12px;
}
