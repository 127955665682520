.billing-information {
  &-card {
    margin-bottom: 30px;
    border-radius: 30px 30px 0px 0px;
    background: #fff;
    box-shadow: 0px -11px 16px rgba(61, 113, 255, 0.16);
  }
  &-title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 25px 25px 0px;
  }
  &-lists {
    padding: 15px 25px 50px;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-edit-btn {
    width: 100%;
    display: block;
    margin-top: 10px;
  }
}

.shipping-method {
  &-card {
    margin-bottom: 30px;
    border-radius: 30px 30px 0px 0px;
    background: #fff;
    box-shadow: 0px -11px 16px rgba(61, 113, 255, 0.16);
  }
  &-title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 0px 20px 0px;
  }
  &-lists {
    padding: 15px 25px 25px;
  }
  &-list {
    display: block;
    position: relative;
    input[type="radio"] {
      position: absolute;
      visibility: hidden;
    }

    label {
      display: block;
      position: relative;
      padding: 8px 0px 8px 30px;
      z-index: 9;
      cursor: pointer;
      font-size: 14px;
    }

    .check {
      display: block;
      position: absolute;
      border: 2px solid $color-radical-red;
      border-radius: 100%;
      height: 16px;
      width: 16px;
      top: 50%;
      margin-top: -10px;
      left: 0px;
      z-index: 5;
      -webkit-transition: all 0.25s linear;
      transition: all 0.25s linear;
      &::before {
        display: block;
        position: absolute;
        content: "";
        border-radius: 100%;
        height: 6px;
        width: 6px;
        top: 50%;
        left: 50%;
        margin: -3px;
        -webkit-transition: background 0.25s linear;
        transition: background 0.25s linear;
      }
    }

    input[type="radio"]:checked ~ .check::before {
      background: $color-radical-red;
    }
    span {
      i {
        margin-right: 5px;
      }
    }
  }
  &-edit-btn {
    width: 100%;
    display: block;
  }
}

.payment-card {
  border-top: 1px solid #ececec;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  .total-price {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }
}
