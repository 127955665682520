/*Mobile Menu Bottom Area*/
.offcanvas-menu {
  margin: 20px 0;
}

.offcanvas-menu li {
  position: relative;
  padding: 7px 0px;
}
.offcanvas-menu > ul > li {
  border-bottom: 1px solid transparent;
}
.offcanvas-menu > ul > li:last-child {
  border-bottom: none;
}
.offcanvas-menu li a {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}
.offcanvas-menu li .mobile-sub-menu {
  display: none;
}

.offcanvas-menu-expand::before,
.offcanvas-menu-expand::after {
  position: absolute;
  top: 17px;
  right: 0px;
  z-index: 10;
  height: 1px;
  width: 15px;
  opacity: 1;
  background-color: $color-radical-red;
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  content: "";
}

.offcanvas-menu-expand::after {
  transform: rotate(90deg);
}

.offcanvas-menu .active > .offcanvas-menu-expand:last-child,
.sub-menu > .active .offcanvas-menu-expand:last-child {
  border-bottom: 1px solid #2f3239;
}

.offcanvas-menu .active > .offcanvas-menu-expand::after,
.sub-menu > .active .offcanvas-menu-expand::after {
  transform: rotate(0deg);
}

/*Mobile Menu Contact Info*/
.mobile-contact-info {
  .logo {
    margin: 0 auto;
    width: 100px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .address {
    margin-top: 30px;
    span {
      display: block;
    }
  }
  .social-link {
    li {
      display: inline-block;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    li a {
      display: block;
      text-align: center;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      background: #2f3239;
      transition: $transition-base;
      font-size: 16px;

      &:hover {
        color: #2f3239;
      }
    }
  }

  .user-link {
    margin-top: 40px;
    li {
      display: inline-block;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.mobile-sub-menu {
  a {
    padding-left: 10px;
    font-size: 12px;
  }
}
