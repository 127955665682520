// Profile Card
.profile-card-wrapper {
  position: relative;
  padding: 35px 40px;
  border-radius: 10px;
  background: $color-white;
  display: flex;
  align-items: center;
  gap: 25px;

  h2 {
    font-size: 18px;
  }
  .profile-shape {
    position: absolute;
  }

  .profile-shape-1 {
    top: 0;
    left: 0;
  }
  .profile-shape-2 {
    bottom: -5px;
    right: 0;
  }

  .image {
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .content {
    .title {
      font-weight: 500;
      margin-bottom: 2px;
    }

    .email,
    .id-num {
      display: block;
    }

    .email {
      margin-bottom: 8px;
    }

    .id-num {
      font-size: 13px;
    }
  }
}

// Profile Details
.profile-details-top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text {
    display: flex;
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 10px;
    color: #929292;
  }

  .price {
    font-weight: 900;
    font-size: 16px;
    color: #1c1c1e;
  }
}

.profile-details-bottom {
  margin-top: 30px;
}
.profile-user-list {
  .profile-list-item {
    border-top: 1px solid rgba(#707070, 0.1);
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.profile-single-list {
  .list-item:not(:last-child) {
    margin-bottom: 28px;
  }

  .profile-link {
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 500;
  }

  .icon {
    font-size: 18px;
    line-height: 1;
  }
}
